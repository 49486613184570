import React, { useEffect } from "react"
const loadText = require("src/utils").loadText
import Footer from "../../components/footer"
import Form from "../../components/form"
import { sendData } from "../../components/form-biz"
import { ConsultationPopButton } from "../../components/form-biz"
import SEO from "../../components/SEO"
import "../../styles/lp-cdn-india.less"
const static_data = loadText("cdn")
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
const settings = {
  className: "center",
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  autoplaySpeed: 5000,
  rows: 1,
  slidesPerRow: 1,
  dots: true,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        rows: 1,
        slidesToShow: 1,
      },
    },
  ],
  autoplay: true,
}
const settings2 = {
  ...settings,
  slidesPerRow: 3,
  responsive: [
    {
      breakpoint: 1240,
      settings: {
        rows: 1,
        slidesPerRow: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        rows: 1,
        slidesPerRow: 1,
      },
    },
  ],
}

import { loadCssLinkToHead } from "../../utils"

const styleInHeader = [
  "/lp-cdn-india/assets/css/bootstrap.min.css",
  "/lp-cdn-india/assets/style.css",
]

export default function Index() {
  const initHeaderStyle = async () => {
    for (let href of styleInHeader) {
      await loadCssLinkToHead(href)
    }
  }
  const init = async () => {
    await initHeaderStyle()
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <div>
      <SEO {...static_data.seo} featuredImage="/featuredImage/cdn.jpg" />
      <div className="main lp-cdn-india">
        <div className="header">
          <div className="container-fluid">
            <div className="menu-wrapper">
              <div className="brand">
                <h1 className="logo">
                  <a href="#">
                    <img
                      src="/lp-cdn-india/assets/images/logo.svg"
                      alt="zenlayer-logo"
                    />
                  </a>
                </h1>
              </div>
              <div className="header-text">
                Enabling organizations to improve their global user experience
                by instantly lowering latency
              </div>
            </div>
          </div>
        </div>

        <div className="banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center">
                <div className="banner-head">
                  <h2>Zenlayer CDN</h2>
                  <p>
                    Users expect instant access to content, no matter where it
                    originates.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon-box">
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/icon-1.png"
                      alt="global-coverage"
                    />
                  </span>
                  <p>#1 In global coverage </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon-box">
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/icon-2.png"
                      alt="Fast-and-secure"
                    />
                  </span>
                  <p>Fast and secure</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon-box">
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/icon-3.png"
                      alt="last-mile-connection"
                    />
                  </span>
                  <p>&lt; 25ms average last-mile connection</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon-box">
                  <span>
                    <img src="/lp-cdn-india/assets/images/icon-4.png" alt="" />
                  </span>
                  <p>&gt;10ms in major cities</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon-box">
                  <span>
                    <img src="/lp-cdn-india/assets/images/icon-5.png" alt="" />
                  </span>
                  <p>Efficient caching </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon-box">
                  <span>
                    <img src="/lp-cdn-india/assets/images/icon-6.png" alt="" />
                  </span>
                  <p>Minimal loading time</p>
                </div>
              </div>
            </div>
          </div>
          {/*banner-slider-goes-here*/}
          <div className="banner-slider">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider-container">
                    <div className="owl-carousel owl-theme banner-slide">
                      <Slider {...settings}>
                        <div className="slide">
                          <h2>Speed, reliability and security at the Edge</h2>
                          <p>
                            No more waits. Enhance the performance of your
                            websites and applications instantly with Zenlayer
                            CDN.
                          </p>
                        </div>
                        <div className="slide">
                          <h2>Optimal digital experience</h2>
                          <p>
                            No more waits. Access your files, pictures, content
                            instantly with CDN.
                          </p>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-wrapper">
                    <Form
                      type="indiaLp"
                      // onSuccessWithoutModal={() => {
                      //   navigate("/lp/thanks")
                      // }}
                      onSubmit={async data => {
                        await sendData({
                          data,
                          url:
                            "https://go.zenlayer.com/l/879902/2021-05-18/4n6yv",
                          source: "India CDN Landing Page",
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*banner-slider-ends-here*/}
        </div>
        {/*banner-ends-here*/}
        {/*section-cdn-intro-goes-here*/}
        <div className="section-cdn-intro">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="text-container">
                  <h3 className="mb-80">Know CDN</h3>
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/cdn.png"
                      alt="other cdn, globe"
                    />
                  </span>
                  <p>
                    A Content Delivery Network (CDN) is a network of web servers
                    or Points of Presence (PoP) distributed globally. A CDN
                    overcomes the drawbacks of the conventional method involving
                    only one central server. With a CDN, content is replicated
                    and stored so that users can quickly access data from a
                    server closest to their location, thus taking care of
                    traffic congestion and enhancing web performance.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="text-container">
                  <h3>Zenlayer CDN - Built for performance</h3>
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/z-cdn.png"
                      alt="zenlayer cdn"
                    />
                  </span>
                  <p>
                    No matter where your users are, Zenlayer CDN has a server
                    near them. Built on the Zenlayer global network, the
                    intelligent routing in the CDN ensures smooth, fast, and
                    reliable distributed delivery to your users even during
                    demand spikes. An intuitive interface makes it easy to add
                    content distribution on six continents with deliveries in
                    under 30 ms worldwide and in under 10 ms to any major city.
                    You can view real-time analytics and adjust your network in
                    case of a sudden increase in user base due to events or
                    updates. With a pay-as-you-go plan, good connectivity, and
                    highly secure content and network, Zenlayer CDN is becoming
                    a popular choice for businesses. So, if you want to ensure a
                    reliable content delivery with zero packet loss between
                    nodes, contact Zenlayer CDN.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*section-cdn-intro-ends-here*/}
        {/*section-around-globe-goes-here*/}
        <div
          className="section-around-globe"
          style={{
            backgroundImage:
              "url(/lp-cdn-india/assets/images/around-globe.png)",
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <span>
                  <img
                    src="/lp-cdn-india/assets/images/globe.png"
                    alt="worldmap"
                  />
                </span>
              </div>
              <div className="col-lg-6">
                <div className="text-container">
                  <h3>Over 70 edge locations in India and Southeast Asia</h3>
                  <p>
                    India and indonesia are the fastest growing digital markets
                    as of 2020 and combined have well over 900 million internet
                    users. Zenlayer has over 70 edge locations dedicated to
                    serving these users and others in the APAC region for an
                    average latency of just 30 ms and under 10 ms in major
                    cities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*section-around-globe-ends-here*/}
        {/*section-benefits-goes-here*/}
        <div className="section-benefits">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center">
                <h3>Benefits of Zenlayer CDN</h3>
              </div>
              <div className="col-md-4">
                <div className="icon-box">
                  <h4>Connectivity</h4>
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/benefit-1.png"
                      alt="connectivity"
                    />
                  </span>
                  <ul>
                    <li>Intelligent routing with high network redundancy</li>
                    <li>Global, private backbone</li>
                    <li>Transmission protocol optimization</li>
                    <li>Geolocation: DNS+IP</li>
                    <li>Content Optimization</li>
                    <li>WebSocket support</li>
                    <li>Rich API support</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="icon-box">
                  <h4>Security</h4>
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/benefit-2.png"
                      alt="Security"
                    />
                  </span>
                  <ul>
                    <li>HTTPS secure cloud acceleration</li>
                    <li>TLS support</li>
                    <li>Support for single and double certificates</li>
                    <li>IP black and white lists</li>
                    <li>Referrer anti-theft chain</li>
                    <li>Back-to-source verification</li>
                    <li>URL encryption</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="icon-box">
                  <h4>Reliability</h4>
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/benefit-3.png"
                      alt="Reliability"
                    />
                  </span>
                  <ul>
                    <li>Zero packet loss between nodes</li>
                    <li>Real-time monitoring</li>
                    <li>Failover</li>
                    <li>Redundant clusters</li>
                    <li>Load balancing</li>
                    <li>Smart dispatch</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*section-benefits-ends-here*/}
        {/*section-global-goes-here*/}
        <div className="section-global">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center">
                <h3> Zenlayer Global Accelerator</h3>
              </div>
              <div className="col-md-2 mobile-center">
                <span>
                  <img
                    src="/lp-cdn-india/assets/images/global-icon.png"
                    alt="Zenlayer Global Accelerator"
                  />
                </span>
              </div>
              <div className="col-md-10">
                <div className="sub-head">
                  <h4>
                    Instantly accelerates dynamic content and reduces
                    application latency for users worldwide. You tell us what
                    you need and where, our Zenlayer team handles the rest.
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <p>
                  Built on Zenlayer’s private global backbone, Global
                  Intelligent Accelerator is highly responsive and
                  instantaneously enhances end-to-end interactive digital
                  experiences across various industries worldwide
                </p>
              </div>
              <div className="col-md-4">
                <div className="text-container">
                  <h5>Instantly accelerates user access to</h5>
                  <ul>
                    <li>
                      Dynamic content like live streaming/VOD of games with
                      player actions
                    </li>
                    <li>
                      Secure operations like user authentication and payments
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="text-container box-center-sm">
                  <h5>Industries </h5>
                  <ul>
                    <li>Gaming</li>
                    <li>Media</li>
                    <li>Health Care</li>
                    <li>Education</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="text-container box-center-md">
                  <h5>Benefits</h5>
                  <ul>
                    <li>Exceptional speeds</li>
                    <li>Improved user satisfaction</li>
                    <li>Increased revenue</li>
                    <li>Highly responsive</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-12"
                style={{ textAlign: "center", margin: "40px 0 70px 0" }}
              >
                {/* <div className="page-btn">
                  <a href="#">Get started</a>
                </div> */}
                <ConsultationPopButton source="cdn-india-lp">
                  Get started
                </ConsultationPopButton>
              </div>
              <div className="col-lg-12 text-center">
                <h3>CDN use cases</h3>
                <p>
                  Use Zenlayer CDN to reduce load times for any kind of content
                </p>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 text-center">
                <div className="icon-box-circle">
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/case-1.png"
                      alt="Pictures-icon"
                    />
                  </span>
                  <h6>Pictures</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 text-center">
                <div className="icon-box-circle">
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/case-2.png"
                      alt="Videos-icon"
                    />
                  </span>
                  <h6>Videos</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 text-center">
                <div className="icon-box-circle">
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/case-3.png"
                      alt="Music-icon"
                    />
                  </span>
                  <h6>Music</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 text-center">
                <div className="icon-box-circle">
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/case-4.png"
                      alt="Game-icon"
                    />
                  </span>
                  <h6>Game data</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 text-center">
                <div className="icon-box-circle">
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/case-5.png"
                      alt="web-icon"
                    />
                  </span>
                  <h6>Web content</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 text-center">
                <div className="icon-box-circle">
                  <span>
                    <img
                      src="/lp-cdn-india/assets/images/case-6.png"
                      alt="files-icon"
                    />
                  </span>
                  <h6>Large files</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-products">
          <div className="container-fluid">
            <div className="text-center">
              <h3>Other Zenlayer products</h3>
            </div>
            <div className="product-slider">
              <div className="owl-carousel owl-theme product-carousel">
                <Slider {...settings2}>
                  <div className="slide bmc">
                    <div className="row">
                      <div className="col-12">
                        <h4>Bare Metal Cloud</h4>
                      </div>
                      <div className="col-8">
                        <ul>
                          <li>
                            High-performance computing + cloud-like flexibility
                          </li>
                          <li>Activate servers in less than 10 minutes</li>
                        </ul>
                      </div>
                      <div className="col-4">
                        <span>
                          <img
                            src="/lp-cdn-india/assets/images/bmc.svg"
                            alt="Bare Metal Cloud"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="slide cdn">
                    <div className="row">
                      <div className="col-12">
                        <h4>Content Delivery Network</h4>
                      </div>
                      <div className="col-8">
                        <ul>
                          <li>Easily deliver your content anywhere</li>
                          <li>
                            Ultra-low latency for pictures, videos, and other
                            files
                          </li>
                        </ul>
                      </div>
                      <div className="col-4">
                        <span>
                          <img
                            src="/lp-cdn-india/assets/images/cdn.svg"
                            alt="Content Delivery Network"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="slide gia">
                    <div className="row">
                      <div className="col-12">
                        <h4>Zenlayer Global Accelerator</h4>
                      </div>
                      <div className="col-8">
                        <ul>
                          <li>
                            Accelerate dynamic content for improved user
                            experience
                          </li>
                          <li>Reduce application and platform latency</li>
                        </ul>
                      </div>
                      <div className="col-4">
                        <span>
                          <img
                            src="/lp-cdn-india/assets/images/gia.svg"
                            alt="Zenlayer Global Accelerator"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="slide ipt">
                    <div className="row">
                      <div className="col-12">
                        <h4>IP Transit</h4>
                      </div>
                      <div className="col-8">
                        <ul>
                          <li>Optimized network connections worldwide</li>
                          <li>
                            Extensive redundant connectivity for ultra-reliable
                            networks
                          </li>
                        </ul>
                      </div>
                      <div className="col-4">
                        <span>
                          <img
                            src="/lp-cdn-india/assets/images/ipt.svg"
                            alt="IP Transit"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="slide edcs">
                    <div className="row">
                      <div className="col-12">
                        <h4>Edge Data Center Services</h4>
                      </div>
                      <div className="col-8">
                        <ul>
                          <li>
                            Custom colocation and managed hosting solutions
                          </li>
                          <li>
                            Choose from over 180 locations for minimal latency
                          </li>
                        </ul>
                      </div>
                      <div className="col-4">
                        <span>
                          <img
                            src="/lp-cdn-india/assets/images/edcs.svg"
                            alt="Edge Data Center Services"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="slide sdn">
                    <div className="row">
                      <div className="col-12">
                        <h4>Cloud Networking</h4>
                      </div>
                      <div className="col-8">
                        <ul>
                          <li>Build a global private network in minutes</li>
                          <li>
                            On-demand, dedicated connections between data
                            centers, public clouds.
                          </li>
                        </ul>
                      </div>
                      <div className="col-4">
                        <span>
                          <img
                            src="/lp-cdn-india/assets/images/sdn.svg"
                            alt="Cloud Networking"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
